"use client";

import { useCallback, useContext, useEffect, useState } from "react";
import {
  DealershipConfiguration,
  DealershipContext,
} from "./dealership-context";
import Cookies from "js-cookie";
import { useUser } from "@clerk/nextjs";
import { UserContext } from "./user-context";
import { currentUser } from "@clerk/nextjs/dist/types/server";

export type Dealership = {
  name: string;
  key: string;
  id: string;
  _id?: string;
  active: boolean;
  dealershipConfiguration: DealershipConfiguration;
  features?: string[];
};

export const DealershipContextWrapper = ({ children }) => {
  const { user, isLoading, getUserGroups, setUserGroups } =
    useContext(UserContext);
  const [userDealerships, setUserDealerships] = useState<Dealership[]>([]);
  const [currentDealership, setCurrentDealership] = useState<Dealership>();

  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchUserDealerships = async () => {
      const response = await fetch(`/v2/api/user/${user.id}/dealerships`);

      if (!response.ok) {
        console.error("Failed to fetch dealerships");
        return;
      }

      const data: any[] = await response.json();

      setUserDealerships(
        data.map((dealership) => ({
          name: dealership.name,
          key: dealership.key,
          id: dealership._id,
          active: dealership.active,
          dealershipConfiguration: dealership.dealershipConfiguration,
          features: dealership.features,
        })),
      );
    };
    fetchUserDealerships();
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const fetchFeatureList = async () => {
      const response = await fetch(`/v1/api/features`);

      if (!response.ok) {
        console.error("Failed to fetch features");
        return;
      }

      const data = await response.json();
      // setAllFeatures(data);
    };
    fetchFeatureList();
  }, [user]);

  const cachedDealershipId = Cookies.get("dealershipId");

  useEffect(() => {
    if (!userDealerships || userDealerships.length < 1) return;

    const found = userDealerships.find(
      (dealership) => dealership.id === cachedDealershipId,
    );

    if (!found) {
      Cookies.remove("dealershipId");
      setCurrentDealership(userDealerships[0]);
      Cookies.set("dealershipId", userDealerships[0].id);
      return;
    }

    setCurrentDealership(found);
  }, [userDealerships, cachedDealershipId]);

  useEffect(() => {
    const setupUser = async () => {
      if (!user || !currentDealership) return;

      const userGroups = await getUserGroups(
        currentDealership._id ?? currentDealership.id,
      );

      setUserGroups(userGroups);
    };
    setupUser();
  }, [currentDealership]);

  const hasFeature = useCallback(
    (feature: string) => {
      if (!currentDealership || !currentDealership.features) return false;

      return currentDealership.features.includes(feature);
    },
    [currentDealership],
  );

  const ctx: DealershipContext = {
    currentDealership,
    setCurrentDealership: (dealership) => {
      Cookies.set("dealershipId", dealership.id);
    },
    dealerships: userDealerships,
    setDealerships: setUserDealerships,
    hasFeature,
  };

  return (
    <DealershipContext.Provider value={ctx}>
      {children}
    </DealershipContext.Provider>
  );
};
